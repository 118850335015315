import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Snackbar } from "@mui/material";

import { login, singleSignOnUrl } from "./api/auth";
import { useNavigate } from "react-router-dom";
import customTheme from "./customTheme";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { logout } from "./api/auth";
import { GenerateTOTPURL } from "./api/auth";
import { localLogin } from "./api/auth";
import { getUserInfo } from "./api/general";
import { userInformation } from "./api/general";
import { getSSOLink } from "./api/auth";
function Login() {
  let navigate = useNavigate();
  let theme = customTheme();

  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState("none");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("Email required");
  const emailRegex = /\S+@\S+\.\S+/;
  const [openSnack, setOpenSnack] = useState(false);

  const [disableInput, setDisableInput] = useState(false);
  const [emailInputClass, setEmailInputClass] = useState("xrx-input");

  const [errorMessage, setErrorMessage] = useState(
    "Could not log in. Try again."
  );
  const signOnUrl = singleSignOnUrl();

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const loginURL = window.location.href;

  function disableUI() {
    setDisableInput(true);
    setDisableButton(true);
  }

  useEffect(() => {
    if (emailRegex.test(email) && email.length > 1) {
      setValidEmail(true);
      setEmailHelperText("");
    } else {
      if (email.length < 1) {
        setValidEmail(false);
        setEmailHelperText("Email required");
      } else {
        setValidEmail(false);
        setEmailHelperText("Invalid email");
      }
    }
  }, [email]);
  function goDashboard() {
    navigate("/dashboard", { replace: true });
    window.location.reload();
  }

  const partnerLogin = async () => {
    disableUI(true);
    let authCode = loginURL.substring(
      loginURL.indexOf("code=") + 5,
      loginURL.length
    );
    console.log(loginURL);
    const xrxLogin = await localLogin(authCode);
    if (xrxLogin.status === 200) {
      let userinfo = await getUserInfo();
      await userInformation();
      if ((userinfo.status = 200)) {
        console.log("Log in completed will go to dashboard");
        setTimeout(() => {
          goDashboard();
        }, 1000);
      }
    } else {
      setErrorMessage(xrxLogin.data.message);
      setOpenSnack(true);
      setDisableInput(false);
    }
  };
  useEffect(() => {
    if (loginURL.includes("code=")) {
      setLoading(true);
      partnerLogin();
    }
  }, []);

  useEffect(() => {
    if (validEmail) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [validEmail]);
  useEffect(() => {
    logout();
  }, []);

  async function handleClick() {
    disableUI();
    setLoading("block");
    const singleLogin = await getSSOLink(email);

    if (singleLogin.status === 200) {
      const SSOURL = singleLogin.data.identityProviderURL;
      window.location.href = SSOURL;
      disableUI();
    } else {
      if ("message" in singleLogin) {
        setErrorMessage(singleLogin.response.data.message);
        setOpenSnack(true);
        setLoading("none");
        setDisableButton(false);
        setDisableInput(false);
      }
    }
  }
  function handleUserLogin() {
    navigate("/user-login");
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <h2>{process.env.REACT_APP_NAME}</h2>
          </Grid>
          <Grid item class="login-ui">
            <p>Log in with your company domain</p>
            <label for="example9" class="input-label">
              Email *
            </label>
            <div id="email-input" class={emailInputClass}>
              <div class="input-group ">
                <input
                  width="400"
                  disabled={disableInput}
                  id="email"
                  type="text"
                  class="form-control"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => {
                    setEmailInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setEmailInputClass("xrx-input");
                  }}
                  aria-label="Email"
                  error={!validEmail}
                  required
                  onKeyDown={(event) => {
                    if (validEmail && event.key === "Enter") {
                      handleClick();
                    }
                  }}
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{emailHelperText}</div>
              </div>
            </div>

            <br></br>
            <button
              type="button"
              class="xrx-btn btn-primary btn-block btn-login"
              disabled={disableButton}
              onClick={handleClick}
            >
              <i class="xgl-log_in xglsize-24"></i>
              <span class="btn-label">Continue</span>
            </button>
            <br></br>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login"
              onClick={handleUserLogin}
              //disabled={disableForgot}
            >
              <span class="btn-label">Log In With Username & Password</span>
            </button>
          </Grid>

          <Grid item></Grid>
          <Grid item class="login-ui" marginTop={2}></Grid>

          <Grid
            justify="center"
            item
            sx={{
              display: loading,
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
          <Grid>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              onClose={handleCloseSnack}
              message={errorMessage}
              autoHideDuration={3000}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
