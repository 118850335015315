import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

export default function MainButton() {
  let navigate = useNavigate();
  function handleClick() {
    navigate("/upload");
  }
  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <button
          onClick={handleClick}
          type="button"
          class="xrx-btn btn-primary btn-block"
        >
          <i class="xgl-file_add xglsize-24"></i>
          <span class="btn-label">File Upload</span>
        </button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <button
          onClick={handleClick}
          type="button"
          class="xrx-btn btn-primary btn-block"
        >
          <i class="xgl-file_add xglsize-24"></i>
        </button>
      </Box>
    </>
  );
}
