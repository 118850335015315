import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";

function LineCustomChart() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <>
      <LineChart
        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
        series={[
          {
            data: [2, 5.5, 2, 8.5, 1.5, 5],
            color: "#3d58c1",
          },
        ]}
        width={matches ? 700 : 350}
        height={300}
      />
    </>
  );
}

export default LineCustomChart;
