import SvelteJSONEditor from "../../../SvelteJSONEditor";
import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip, IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from "@mui/icons-material/Restore";
import SaveIcon from "@mui/icons-material/Save";
import {
  GetDocumentDetailInURL,
  UpdateDocumentOutput,
} from "../../../api/document";
import { useParams } from "react-router-dom";
import { GetDocumentDetail } from "../../../api/document";

function JsonView(data) {
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableRestoreButton, setDisableRestoreButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  const [disableValidateButton, setDisableValidateButton] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const documentID = useParams();
  const [content, setContent] = useState({
    json: data.data.json,
    text: undefined,
  });
  const handleValidateButton = async (event) => {
    const updateDocument = await UpdateDocumentOutput(
      documentID.id,
      content.json,
      1
    );
    if (updateDocument === 200) {
      disableUI();
    }
  };
  async function viewValidate() {
    const URLData = await GetDocumentDetailInURL(documentID.id, "0");
    if (URLData) {
      disableUI(true);
    }
  }
  useEffect(() => {
    viewValidate();
  });
  useEffect(() => {
    setContent({
      json: data.data.json,
      text: undefined,
    });
  }, [data]);

  function disableUI() {
    setReadOnly(true);
    setDisableRestoreButton(true);
    setDisableSaveButton(true);
    setDisableValidateButton(true);
    setDisablePreviousButton(true);
    setDisableEditButton(true);
  }
  const handleSaveButton = async (event) => {
    setReadOnly(true);
    setDisableRestoreButton(false);
    setDisableSaveButton(true);
    const updateDocument = await UpdateDocumentOutput(
      documentID.id,
      content.json,
      0
    );
    console.log(updateDocument);
  };

  const handleEditButton = (event) => {
    setReadOnly(false);

    setDisableSaveButton(false);
  };

  const handlePreviousButton = async (event) => {
    const getOgDocument = await GetDocumentDetail(documentID.id, "2");
    setContent({
      json: JSON.parse(getOgDocument),
      text: undefined,
    });
    setDisableSaveButton(false);
    setDisableRestoreButton(true);
  };

  const handleRestoreButton = async (event) => {
    const getOgDocument = await GetDocumentDetail(documentID.id, "1");
    setContent({
      json: JSON.parse(getOgDocument),
      text: undefined,
    });
    setDisableSaveButton(false);
    setDisableRestoreButton(true);
  };
  return (
    <>
      <Grid item sm={6}>
        <Grid container justify="center">
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Tooltip title="Restore" arrow>
              <IconButton
                color="info"
                onClick={handleRestoreButton}
                disabled={disableRestoreButton}
              >
                <RestoreIcon></RestoreIcon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Tooltip title="Previous" arrow>
              <IconButton
                color="info"
                onClick={handlePreviousButton}
                disabled={disablePreviousButton}
              >
                <UndoIcon></UndoIcon>
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Tooltip title="Edit" arrow>
              <IconButton
                color="info"
                onClick={handleEditButton}
                disabled={disableEditButton}
              >
                <EditIcon></EditIcon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Tooltip title="Save" arrow>
              <IconButton
                color="info"
                onClick={handleSaveButton}
                disabled={disableSaveButton}
              >
                <SaveIcon></SaveIcon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Tooltip
              title="Validate"
              onClick={handleValidateButton}
              disabled={disableValidateButton}
              arrow
            >
              <IconButton color="info">
                <DoneAllIcon></DoneAllIcon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
      <Box>
        <div class="my-json-editor">
          <SvelteJSONEditor
            content={content}
            readOnly={readOnly}
            onChange={setContent}
          />
        </div>
      </Box>
    </>
  );
}
export default JsonView;
