import axios from "axios";
const authApiUrl = process.env.REACT_APP_AUTH_API_URL;

export function singleSignOnUrl() {
  return (
    "https://anydoc-poc-xerox-login.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Xerox&redirect_uri=" +
    process.env.REACT_APP_SS0_REDIRECT_URL +
    "&response_type=CODE&client_id=30m0k9g8tdlh6u8m8iv0vdke90&scope=aws.cognito.signin.user.admin email openid profile"
  );
}

var inputUser;
var inputPassword;

export async function login(user, pass) {
  return await axios
    .post(authApiUrl + "/api/Auth/Login", {
      UserName: user,
      Password: pass,
    })
    .then(function (response) {
      if (response.data.message.value === "NEW_PASSWORD_REQUIRED") {
        return "NEW_PASSWORD_REQUIRED";
      }
      if (response.status === 200) {
        inputUser = user;
        inputPassword = pass;
        // sessionStorage.setItem("user", user);
        // sessionStorage.setItem("pass", pass);

        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_token", response.data.access_Token);
        sessionStorage.setItem("refresh_token", response.data.refreshToken);
        //sms();
        GenerateTOTPURL();
      }

      return response;
    })
    .catch(function (error) {
      if (error.response.data.message.includes("SOFTWARE_TOKEN_MFA_CODE")) {
        sessionStorage.setItem("MFAEnabled", true);
        //sessionStorage.setItem("user", user);
        // sessionStorage.setItem("pass", pass);
        inputUser = user;
        inputPassword = pass;
        return error.response;
      } else {
        sessionStorage.setItem("MFAEnabled", false);
        return error;
      }
    });
}
export async function confirmSignUp(user, tempPass, newPass) {
  return await axios
    .post(authApiUrl + "/api/Auth/ConfirmSignUp", {
      UserName: user,
      TemporaryPassword: tempPass,
      NewPassword: newPass,
    })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function mfaLogin(code) {
  const user = inputUser;
  const pass = inputPassword;
  const body = {
    UserName: user,
    Password: pass,
    CodeMFA: code,
  };
  return await axios
    .post(authApiUrl + "/api/Auth/Login", body)
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_token", response.data.access_Token);
        sessionStorage.setItem("refresh_token", response.data.refreshToken);
        console.log("MFA Login Correct");
      }

      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function sms() {
  return await axios
    .post(authApiUrl + "/api/Auth/SMS", {
      UserName: sessionStorage.user,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function RefreshToken() {
  return await axios
    .post(authApiUrl + "/api/Auth/RefreshToken", {
      UserName: sessionStorage.UserName,
      RefreshToken: sessionStorage.refresh_token,
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_token", response.data.access_Token);
        return response.data.token;
      }
    })
    .catch(function (error) {
      return error;
    });
}
/*
export async function verifyCodeSMS(code) {
  return await axios
    .post(authApiUrl + "/api/Auth/VerifyCodeSMS", {
      code: code,
     userName: sessionStorage.user,
      password: sessionStorage.pass,
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_token", response.data.access_Token);
        sessionStorage.setItem("refresh_token", response.data.refreshToken);
        sessionStorage.setItem("expires_in", response.data.expiresIn);
      }
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
*/

export async function enableMFA(code) {
  const token = sessionStorage.token;
  const access_token = sessionStorage.access_token;
  const body = {
    AccessToken: access_token,
    IdToken: code,
  };
  return await axios
    .post(authApiUrl + "/api/Auth/EnableMFA", body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        console.log("MFA Enabled");
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
export async function forgotPassword(userName) {
  return await axios
    .post(authApiUrl + "/api/Auth/ForgotPassword", {
      UserName: userName,
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("UserName", userName);
      }

      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function resetPasswordWithCode(newPassword, code) {
  const body = {
    UserName: sessionStorage.UserName,
    NewPassword: newPassword,
    ConfirmationCode: code,
  };
  return await axios
    .post(authApiUrl + "/api/Auth/ResetPasswordWithConfirmationCode", body, {})
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function changePassword(oldPassword, password, confirmPassword) {
  const token = sessionStorage.token;
  const access_token = sessionStorage.access_token;
  const body = {
    OldPassword: oldPassword,
    Password: password,
    ConfirmPassword: confirmPassword,
    Token: access_token,
  };
  return await axios
    .post(authApiUrl + "/api/Auth/ChangePassword", body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("pass", password);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function GenerateTOTPURL() {
  const token = sessionStorage.token;
  const access_token = sessionStorage.access_token;
  const body = {
    AccessToken: access_token,
  };
  return await axios
    .post(authApiUrl + "/api/Auth/GenerateTOTPURL", body, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("auth_url", response.data.url);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
export async function logout() {
  sessionStorage.clear();
  window.history.replaceState(null, "", window.location.pathname);
}

export async function singleSignOn() {}

export async function localLogin(code) {
  return await axios
    .post(authApiUrl + "/api/Auth/GetTokenByCode", {
      Code: code,
      TokenRedirectURL: process.env.REACT_APP_SS0_REDIRECT_URL,
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("access_token", response.data.access_Token);
        sessionStorage.setItem("refresh_token", response.data.refreshToken);
        sessionStorage.setItem("expires_in", response.data.expiresIn);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function userInformation() {
  const user = inputUser;
  if (user && sessionStorage.token) {
    return {
      names: sessionStorage.names,
      surNames: sessionStorage.surNames,
      email: sessionStorage.email,
      countryCode: sessionStorage.countryCode,
      phone: sessionStorage.phone,
      CompanyId: sessionStorage.CompanyId,
      company: sessionStorage.company,
      roleCompanyName: sessionStorage.roleCompanyName,
      welcomeMessage: sessionStorage.welcomeMessage,
      //display wizard
    };
  } else {
    return {};
  }
}

export async function getSSOLink(email) {
  return await axios
    .post(authApiUrl + "/api/Auth/GetSSOLink", {
      email: email,
      RedirectUri: process.env.REACT_APP_SS0_REDIRECT_URL,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
