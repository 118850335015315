import React from "react";
import { useState, useEffect } from "react";
import { Grid, Box, Snackbar } from "@mui/material";

import { login, singleSignOnUrl } from "./api/auth";
import { useNavigate } from "react-router-dom";
import customTheme from "./customTheme";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { logout } from "./api/auth";
import { GenerateTOTPURL } from "./api/auth";
import { localLogin } from "./api/auth";
import { getUserInfo } from "./api/general";
import { userInformation } from "./api/general";
function UserLogin() {
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState("none");
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] =
    useState("Password required");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("Email required");
  const emailRegex = /\S+@\S+\.\S+/;
  const [openSnack, setOpenSnack] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [emailInputClass, setEmailInputClass] = useState("xrx-input");
  const [passwordInputClass, setPasswordInputClass] = useState("xrx-input");

  const [errorMessage, setErrorMessage] = useState(
    "Could not log in. Try again."
  );
  const signOnUrl = singleSignOnUrl();
  const [disableButtonXerox, setDisableButtonXerox] = useState(false);
  const [disableForgot, setDisableForgot] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const loginURL = window.location.href;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotClick = (event) => {
    event.preventDefault();
    navigate("/forgotPassword");
  };
  const handleGoBack = (event) => {
    event.preventDefault();
    navigate("/login");
  };
  function disableUI() {
    setDisableInput(true);
    setDisableButton(true);
    setDisableButtonXerox(true);
    setDisableForgot(true);
    setDisableInput(true);
  }
  function goDashboard() {
    navigate("/dashboard", { replace: true });
    window.location.reload();
  }
  let navigate = useNavigate();
  let theme = customTheme();
  //xeroxLogin();

  useEffect(() => {
    if (emailRegex.test(email) && email.length > 1) {
      setValidEmail(true);
      setEmailHelperText("");
    } else {
      if (email.length < 1) {
        setValidEmail(false);
        setEmailHelperText("Email required");
      } else {
        setValidEmail(false);
        setEmailHelperText("Invalid email");
      }
    }
  }, [email]);

  useEffect(() => {
    if (password.length > 5) {
      setValidPassword(true);
      setPasswordHelperText("");
    }
    if (password.length > 1 && password.length < 6) {
      setValidPassword(false);
      setPasswordHelperText("Password too short");
    }
    if (password.length < 1) {
      setValidPassword(false);
      setPasswordHelperText("Password required");
    }
  }, [password]);

  useEffect(() => {
    if (validEmail && validPassword) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [validPassword, validEmail]);
  useEffect(() => {
    logout();
  }, []);
  async function handleClick() {
    disableUI();
    setLoading("block");
    const correctLogin = await login(email, password);
    if (correctLogin === "NEW_PASSWORD_REQUIRED") {
      navigate("/NewPassword", { state: { user: email, pass: password } });
    }
    if (
      correctLogin.status === 200 &&
      !(correctLogin === "NEW_PASSWORD_REQUIRED")
    ) {
      sessionStorage.setItem("MFAEnabled", false);
      const TOTPURL = await GenerateTOTPURL();
      console.log(TOTPURL);
      navigate("/twofa");
    }
    if (JSON.parse(sessionStorage.MFAEnabled)) {
      navigate("/twofa");
    } else {
      if ("message" in correctLogin) {
        setDisableForgot(false);
        setErrorMessage(correctLogin.response.data.message);
      }
    }
    setLoading("none");
    setDisableButton(false);
    setDisableInput(false);
    setOpenSnack(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <h2>{process.env.REACT_APP_NAME}</h2>
          </Grid>
          <Grid item class="login-ui">
            <label for="example9" class="input-label">
              Email *
            </label>
            <div id="email-input" class={emailInputClass}>
              <div class="input-group ">
                <input
                  width="400"
                  disabled={disableInput}
                  id="email"
                  type="text"
                  class="form-control"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => {
                    setEmailInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setEmailInputClass("xrx-input");
                  }}
                  aria-label="Email"
                  error={!validEmail}
                  required
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{emailHelperText}</div>
              </div>
            </div>
            <label for="example9" class="input-label">
              Password *
            </label>
            <div id="password-input" class={passwordInputClass}>
              <div class="input-group">
                <input
                  width={300}
                  id="example9"
                  class="form-control"
                  aria-label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={(e) => {
                    setPasswordInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setPasswordInputClass("xrx-input");
                  }}
                  disabled={disableInput}
                  required
                />
                <div class="input-group-append">
                  <button
                    class="xrx-btn btn-inline btn-primary btn-glyph"
                    type="button"
                    onClick={handleClickShowPassword}
                  >
                    <i
                      class={
                        showPassword
                          ? "xgl-view_disabled xglsize-24"
                          : "xgl-view xglsize-24"
                      }
                    ></i>
                    <span class="sr-only">View</span>
                  </button>
                </div>
              </div>
              <div class="form-text">
                <div class="message message-warning">{passwordHelperText}</div>
              </div>
            </div>
            <br></br>
            <button
              type="button"
              class="xrx-btn btn-primary btn-block btn-login"
              disabled={disableButton}
              onClick={handleClick}
            >
              <i class="xgl-log_in xglsize-24"></i>
              <span class="btn-label">Log In</span>
            </button>
            <br></br>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login"
              onClick={handleForgotClick}
              disabled={disableForgot}
            >
              <span class="btn-label">Forgot Password</span>
            </button>
            <br></br>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login"
              onClick={handleGoBack}
              disabled={disableForgot}
            >
              <span class="btn-label">Go Back</span>
            </button>
          </Grid>

          <Grid item></Grid>
          <Grid item class="login-ui" marginTop={2}></Grid>

          <Grid
            justify="center"
            item
            sx={{
              display: loading,
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
          <Grid>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              onClose={handleCloseSnack}
              message={errorMessage}
              autoHideDuration={3000}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default UserLogin;
