import React, { useState, useEffect } from "react";
import { Grid, Box, Snackbar } from "@mui/material";
import { getUserInfo } from "./api/general";
import { userInformation } from "./api/general";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "./customTheme";
import CircularProgress from "@mui/material/CircularProgress";
import GenerateQR from "./api/helpers/generateQR";
import { enableMFA } from "./api/auth";
import { mfaLogin } from "./api/auth";

function Twofa() {
  let theme = customTheme();
  let navigate = useNavigate();
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [codeHelperText, setCodeHelperText] = useState("Six digit required");
  const [loading, setLoading] = useState("none");
  const [disableButton, setDisableButton] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [codeInputClass, setCodeInputClass] = useState("xrx-input");
  const [src, setSrc] = useState("");
  const [showQR, setShowQR] = useState("none");
  const [showTwoSetp, setShowTwoStep] = useState("none");
  const [errorMessage, setErrorMessage] = useState(
    "Could not log in. Try again."
  );
  const handleReturnClick = (event) => {
    event.preventDefault();
    navigate("/login");
  };
  useEffect(() => {
    if (JSON.parse(sessionStorage.MFAEnabled) === false) {
      setSrc(GenerateQR(sessionStorage.auth_url));
      setShowQR("block");
    }
    if (JSON.parse(sessionStorage.MFAEnabled) === true) {
      setShowTwoStep("block");
    }
  }, []);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    if (code.length < 6) {
      setValidCode(false);
      setCodeHelperText("Six digit code required");
    }
    if (code.length === 6) {
      setValidCode(true);
      setCodeHelperText("");
    }
  }, [code]);

  useEffect(() => {
    if (validCode) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [validCode]);
  async function handleClick() {
    setLoading("block");
    setDisableButton(true);
    setDisableInput(true);
    if (JSON.parse(sessionStorage.MFAEnabled)) {
      console.log("Will auth using code");
      let correctMFALogin = await mfaLogin(code);
      if (correctMFALogin.status === 200) {
        let userinfo = await getUserInfo();

        if ((userinfo.status = 200)) {
          userInformation();
          navigate("/dashboard");
        }
      } else {
        setErrorMessage(correctMFALogin.response.data.message);
      }
    } else if (!JSON.parse(sessionStorage.MFAEnabled)) {
      {
        console.log("Will enable MFA");
        let correctCode = await enableMFA(code);
        if (correctCode.status === 200) {
          let userinfo = await getUserInfo();

          if ((userinfo.status = 200)) {
            userInformation();
            navigate("/dashboard");
          }
        } else {
          if (!correctCode.status === 200) {
            console.log(correctCode.status);
            setErrorMessage(correctCode.response.data.message);
          }
        }
      }
    }

    setLoading("none");
    setDisableButton(false);
    setDisableInput(false);
    setOpenSnack(true);
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "90%",
          maxWidth: "750px",
          margin: "0 auto",
          paddingTop: "20px",
        }}
      >
        <Grid
          container
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Box display={showTwoSetp}>
            <Grid item>
              <h2>2-step verification</h2>
              <p>
                Please enter the six digit verification code sent to your
                authenticator app.
              </p>
            </Grid>
          </Box>

          <Box display={showQR}>
            <Grid item>
              <h2>Set up multifactor authentication</h2>
              <p>
                Scan the OR code below using a supported authenticator app (such
                as Google Authenticator, Microsoft Authenticator, Authy,
                Password, Last Pass etc).
              </p>
            </Grid>
            <Grid item mt={5}>
              <Box width={256} height={256} margin={"0 auto"}>
                <div class="xrx-card">
                  <div class="card-body ">
                    {" "}
                    <img
                      class="generated-qr"
                      src={src}
                      alt={"qr-code for enabling mfa"}
                    />
                  </div>
                </div>
              </Box>
            </Grid>
          </Box>

          {/* 
          <Grid item xs={12}>
            <FormControl>
              <TextField
                required
                color="secondary"
                id="outlined-basic"
                label="6 Digit Code"
                variant="outlined"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => setCode(event.target.value)}
                error={!validCode}
                helperText={codeHelperText}
                inputProps={{ maxLength: 6, style: { textAlign: "center" } }}
                disabled={disableInput}
              />
vscode-file://vscode-app/Applications/Visual%20Studio%20Code.app/Contents/Resources/app/out/vs/code/electron-sandbox/workbench/workbench.html            </FormControl>
          </Grid>
  */}
          <Grid item display={showQR}>
            <p>
              Once the authentication app generates a confirmation code, enter
              it below:
            </p>
          </Grid>
          <Grid item width={300} margin={"0 auto"}>
            <label for="code" class="input-label">
              6 Digit Code *
            </label>
            <div class={codeInputClass}>
              <div class="input-group ">
                <input
                  width="300"
                  id="code"
                  type="text"
                  class="form-control"
                  maxLength={6}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.target.value.length === 6 &&
                      event.key === "Enter"
                    ) {
                      handleClick();
                    }
                  }}
                  onChange={(e) => setCode(e.target.value)}
                  onFocus={(e) => {
                    setCodeInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setCodeInputClass("xrx-input");
                  }}
                  aria-label="Code"
                  error={!validCode}
                  disabled={disableInput}
                  required
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{codeHelperText}</div>
              </div>
            </div>
          </Grid>

          <Grid item width={300} margin={"0 auto"} justify={"center"}>
            <div class="submit-login">
              <button
                type="button"
                class="xrx-btn btn-primary btn-block "
                disabled={disableButton}
                onClick={handleClick}
              >
                <i class="xgl-log_in xglsize-24"></i>
                <span class="btn-label">Submit</span>
              </button>
            </div>
          </Grid>
          <Grid item width={300} margin={"0 auto"} justify={"center"}>
            <button
              type="button"
              class="xrx-btn btn-default btn-block "
              onClick={handleReturnClick}
            >
              <span class="btn-label"> Return to Login</span>
            </button>
          </Grid>

          {/* 
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleClick}
              disabled={disableButton}
            >
              <LoginSharpIcon sx={{ mr: 2 }}></LoginSharpIcon> Submit
            </Button>
          </Grid>
 */}
          <Grid
            justify="center"
            item
            width={300}
            margin={"0 auto"}
            textAlign={"center"}
            sx={{
              display: loading,
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
          <Grid>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              onClose={handleCloseSnack}
              message={errorMessage}
              autoHideDuration={3000}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Twofa;
