import React, { Component } from "react";
import UserTable from "./userTable";
import { Grid } from "@mui/material";
import EnhancedTable from "./enhancedTable";
import { useNavigate } from "react-router-dom";
import UserAdmin from "./userAdmin";
import TestTable from "./userTable";
import { getUsers } from "../../api/adminUsers";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { bulkUploadUser } from "../../api/adminUsers";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

import ProtectedRoutes from "../../utils/protectedRoutes";
import { useLocation } from "react-router-dom";

function UserManagement() {
  let location = useLocation();
  let navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  const [rows, setRows] = useState([]);
  //--Snackbar
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const [errorMessage, setErrorMessage] = useState(
    "Could not create user. Try again."
  );
  //Protected Route

  const [validRoute, setValidRoute] = useState(false);
  useEffect(() => {
    async function fetchValidation() {
      const isValid = await ProtectedRoutes(location.pathname);
      setValidRoute(isValid);
      if (!isValid) {
        navigate("/forbidden");
      }
    }
    fetchValidation();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const users = await getUsers(1, 50, "id asc");
      setRows(users.results);
    };

    fetchData();
  }, []);
  const handleAddUserButton = (event) => {
    navigate("/addusers");
  };

  async function handleBulkImport(fileToUpload) {
    // get the selected file from the input
    // create a new FormData object and append the file to it

    const formData = new FormData();
    formData.append("file", fileToUpload[0]);
    const uploadFile = await bulkUploadUser(formData);
    if (uploadFile.status === 200) {
      setErrorMessage("Users imported successfully.");
      setOpenSnack(true);
      setRefresh(true);
    } else {
      if (
        uploadFile.response.status === 400 &&
        uploadFile.response.data.length > 0
      ) {
        for (let index = 0; index < uploadFile.response.data.length; index++) {
          setErrorMessage(
            uploadFile.response.data[index].email +
              " " +
              uploadFile.response.data[index].message
          );
          setOpenSnack(true);
        }
      }
      if (
        uploadFile.response.status === 400 &&
        uploadFile.response.data.length < 1
      ) {
        setErrorMessage("Users could not be imported");
        setOpenSnack(true);
      }
    }
  }
  if (!validRoute) {
    return <> </>;
  }
  return (
    <>
      <Grid container>
        <UserAdmin props={refresh} />
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Stack direction="row" alignItems="center" gap={5}>
          <Grid item xs={6}>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login"
              onClick={handleAddUserButton}
              // disabled={disableSubmit}
            >
              <span class="btn-label">Add User</span>
            </button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              class="xrx-btn btn-default btn-block xrx-blk"
              component="label"
            >
              Bulk Import Users
              <input
                type="file"
                accept=".csv"
                onChange={(e) => handleBulkImport([e.target.files[0]])}
                //multiple
                hidden
                color="secondary"
                excludeAcceptAllOption="true"
              />
            </Button>
          </Grid>
        </Stack>
      </Grid>
      <Grid container>
        <Grid item>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnack}
            onClose={handleCloseSnack}
            message={errorMessage}
            autoHideDuration={3000}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UserManagement;
