import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { GetDocumentDetail } from "./api/document";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetImageDetail } from "./api/document";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { Tooltip } from "@mui/material";
import "./document.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import Link from "@mui/material/Link";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import RenderHighlightAreas from "./screens/document/views/RenderHighlightAreas";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import JsonView from "./screens/document/views/jsonView";
import ExportBar from "./screens/document/helpers/exportBar";
import { TIFFViewer } from "react-tiff";
import "react-tiff/dist/index.css";
function Document() {
  const documentID = useParams();
  //const documentID.id = 12795;
  const state = useLocation();
  let navigate = useNavigate();

  const [extractedDocument, setExtractedDocument] = useState(undefined);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [documentImage, setDocumentImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [prevDocument, setPrevDocument] = useState([]);
  const [nextDocument, setNextDocument] = useState([]);
  const [documents, setDocuments] = useState(state.state.documents);
  const [displayUI, setDisplayUI] = useState("none");
  const [loading, setLoading] = useState("flex");

  const [disableEditButton, setDisableEditButton] = useState(true);
  const [disableEditContent, setDisableEditContent] = useState(false);

  //used for keys
  const handleKeyPress = useCallback((event) => {
    //console.log(`Key pressed: ${event.key}`);
    if (event.ctrlKey === true) {
      console.log(`Key pressed: ${event.key}`);
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  //Used for highlihting
  const [data, setData] = useState(null);
  const [areas, setAreas] = useState([]); // Use state for areas
  const changeArea = (pageIndex, width, height, x, y) => {
    setAreas([
      {
        pageIndex: pageIndex,
        height: height * 100,
        width: width * 100,
        left: x * 100,
        top: y * 100,
      },
    ]);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
    ],
  });
  const [content, setContent] = useState({
    json: "",
    text: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      const AWSData = await GetDocumentDetail(documentID.id, "4");
      setData(JSON.parse(AWSData));
    };

    fetchData();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const getDocument = await GetDocumentDetail(documentID.id, "3");
        setContent({
          json: JSON.parse(getDocument),
          text: undefined,
        });
        setExtractedDocument(JSON.parse(getDocument));
        setDisableEditButton(true);
      } catch (error) {}
      //const getDocument = await GetDocumentDetail(documentID.id, "3");
      const getImage = await GetImageDetail(documentID.id);

      setDocumentImage(getImage);

      setDisplayUI("flex");
      setLoading("none");

      // setData(getAWSDocument);
    })();
  }, [documentID]);

  useEffect(() => {
    const getdocuments = state.state.documents;

    const arrayDocuments = Array.from(getdocuments).filter(
      (item) => item.id == documentID.id
    );
    setCurrentIndex(
      getdocuments.findIndex(function (item) {
        return item.id == documentID.id;
      })
    );
    setDocumentDetails(arrayDocuments);
  }, [documentID]);

  useEffect(() => {
    setNextDocument((currentIndex + 1) % state.state.documents.length);
    setPrevDocument((currentIndex - 1) % state.state.documents.length);
  }, [currentIndex]);

  async function handleNext(event) {
    event.preventDefault();
    navigate("/document/" + documents[nextDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  async function handlePrev(event) {
    event.preventDefault();
    navigate("/document/" + documents[prevDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  if (documentImage.length < 1) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
        sx={{
          display: loading,
        }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: displayUI,
        }}
        style={{
          height: "85vh",
          spacing: 0,
        }}
      >
        {documentDetails.map((document, index) => {
          return (
            <>
              <Grid
                container
                direction="row"
                style={{ width: "100%", textAlign: "center" }}
              >
                <Grid item xs="6">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="large" />}
                    aria-label="breadcrumb"
                    sx={{ ml: { xs: 1, md: 5 }, mb: 0 }}
                  >
                    <Link
                      underline="hover"
                      key="1"
                      to="/completed"
                      style={{ textDecoration: "none" }}
                    >
                      Completed Documents
                    </Link>

                    <Typography key="3" color="text.primary">
                      {document.fileName}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item xs="6">
                  <Box display="flex" justifyContent="flex-end" mr={3}>
                    <ExportBar
                      fileId={documentID}
                      fileName={document.fileName}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: "100%", height: "100%", textAlign: "center" }}
              >
                <Grid item xs>
                  {document.fileName.toLowerCase().includes("pdf") ? (
                    <RenderHighlightAreas
                      fileUrl={documentImage.url}
                      areas={areas}
                    />
                  ) : (
                    ""
                  )}
                  {!document.fileName.toLowerCase().includes("pdf") &&
                  !document.fileName.toLowerCase().includes("tif") ? (
                    <Zoom>
                      <img
                        alt={document.fileName}
                        src={documentImage.url}
                        className="document-photo"
                      />
                    </Zoom>
                  ) : (
                    ""
                  )}

                  {document.fileName.toLowerCase().includes("tif") ? (
                    <TIFFViewer tiff={documentImage.url} />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs>
                  <Tabs>
                    {" "}
                    <TabList>
                      {data?.ExpenseDocuments?.length > 0 ? (
                        <>
                          <Tab>Summary Fields</Tab>
                          <Tab>Line Items</Tab>
                        </>
                      ) : (
                        ""
                      )}
                      <Tab disabled={disableEditContent}>Edit</Tab>{" "}
                    </TabList>
                    {data?.ExpenseDocuments?.length > 0 ? (
                      <TabPanel>
                        <div id="summaryfields">
                          {data?.ExpenseDocuments?.map((expenseDoc) => (
                            <>
                              <div key={expenseDoc.ExpenseIndex}>
                                <div>
                                  {expenseDoc.SummaryFields?.map(
                                    (summaryField, summaryIndex) => (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          border:
                                            "1px solid rgb(170, 183, 184)",
                                          margin: "5px 0",
                                          backgroundColor: "white",
                                        }}
                                        key={summaryIndex}
                                        onClick={(event) =>
                                          changeArea(
                                            summaryField.PageNumber - 1,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Width,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Height,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Left,
                                            summaryField.ValueDetection.Geometry
                                              .BoundingBox.Top
                                          )
                                        }
                                      >
                                        <strong>Type:</strong>{" "}
                                        {summaryField.Type.Text},
                                        <strong> Confidence:</strong>{" "}
                                        {
                                          summaryField.LabelDetection
                                            ?.Confidence
                                        }
                                        ,<strong>Value:</strong>{" "}
                                        {summaryField.ValueDetection?.Text ||
                                          "N/A"}
                                        ,<strong>Label Detection:</strong>{" "}
                                        {summaryField.LabelDetection?.Text ||
                                          "N/A"}
                                        <br />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    {data?.ExpenseDocuments?.length > 0 ? (
                      <TabPanel>
                        <div id="lineItems">
                          {data?.ExpenseDocuments?.map((expenseDoc) => (
                            <div key={expenseDoc.ExpenseIndex}>
                              <div>
                                {expenseDoc.LineItemGroups?.map(
                                  (lnItems, lineItemGroupIndex) =>
                                    lnItems.LineItems?.map(
                                      (lineItem, lineItemIndex) => (
                                        <div
                                          style={{
                                            border:
                                              "1px solid rgb(170, 183, 184)",
                                            margin: "5px 0",
                                            backgroundColor: "white",
                                          }}
                                          key={"row " + (lineItemIndex + 1)}
                                        >
                                          <h4>Line {lineItemIndex + 1}</h4>

                                          {lineItem.LineItemExpenseFields?.map(
                                            (
                                              lineItemExpenseField,
                                              lineItemExpenseFieldIndex
                                            ) => (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  border:
                                                    "1px solid rgb(170, 183, 184)",
                                                  margin: "5px 0",
                                                }}
                                                onClick={(event) =>
                                                  changeArea(
                                                    lineItemExpenseField.PageNumber -
                                                      1,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Width,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Height,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Left,
                                                    lineItemExpenseField
                                                      .ValueDetection.Geometry
                                                      .BoundingBox.Top
                                                  )
                                                }
                                              >
                                                <div
                                                  key={
                                                    lineItemExpenseFieldIndex
                                                  }
                                                >
                                                  <strong>Value:</strong>
                                                  {
                                                    lineItemExpenseField
                                                      .ValueDetection?.Text
                                                  }
                                                </div>

                                                <div>
                                                  {" "}
                                                  <strong>Type:</strong>{" "}
                                                  {
                                                    lineItemExpenseField.Type
                                                      .Text
                                                  }
                                                </div>
                                                <div>
                                                  <strong> Confidence:</strong>{" "}
                                                  {
                                                    lineItemExpenseField
                                                      .ValueDetection
                                                      ?.Confidence
                                                  }
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )
                                    )
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    <TabPanel>
                      <div
                        id="editItems"
                        sx={{
                          display: disableEditContent,
                        }}
                      >
                        <JsonView data={content} />
                      </div>
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
              <Grid
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                alignItems="center"
                justifyContent="center"
                container
              >
                <Grid item sm={6}></Grid>

                <Grid
                  md={6}
                  style={{
                    textAlign: "center",
                    backgroundColor: "yellow",
                  }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                ></Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    backgroundColor: "yellow",
                  }}
                ></Grid>
              </Grid>
              {
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mb: 0, mt: 0 }}
                >
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <Tooltip title="Previous Document">
                      <Link underline="hover" onClick={handleNext}>
                        Prev
                      </Link>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <Tooltip title="Next Document">
                      <Link underline="hover" onClick={handlePrev}>
                        Next
                      </Link>
                    </Tooltip>
                  </Grid>
                </Grid>
              }
            </>
          );
        })}
      </Grid>
    </>
  );
}

export default Document;

/*
                 <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid item>
                      <Box>
                        <div class="my-json-editor">
                          <SvelteJSONEditor
                            content={content}
                            readOnly={readOnly}
                            onChange={setContent}
                          />
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  */
