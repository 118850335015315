import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";

export default function PieCustomChart() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <PieChart
      series={[
        {
          data: [
            { id: 0, value: 10, label: "Bill of Lading" },
            { id: 1, value: 15, label: "Domestic Transcript" },
            { id: 2, value: 20, label: "Identity Documents" },
            { id: 3, value: 20, label: "Invoice" },
          ],
        },
      ]}
      width={matches ? 700 : 350}
      height={300}
    />
  );
}
