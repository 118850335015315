import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "./customTheme";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Snackbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { forgotPassword } from "./api/auth";
import { Typography } from "@mui/material";

function ForgotPassword() {
  const [disableButton, setDisableButton] = useState(true);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("Email required");
  const emailRegex = /\S+@\S+\.\S+/;
  const [loading, setLoading] = useState("none");
  const [disableInput, setDisableInput] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [disableLink, setDisableLink] = useState(false);
  const [emailInputClass, setEmailInputClass] = useState("xrx-input");

  let navigate = useNavigate();
  let theme = customTheme();

  useEffect(() => {
    if (emailRegex.test(email) && email.length > 1) {
      setValidEmail(true);
      setEmailHelperText("");
    } else {
      if (email.length < 1) {
        setValidEmail(false);
        setEmailHelperText("Email required");
      } else {
        setValidEmail(false);
        setEmailHelperText("Invalid email");
      }
    }
  }, [email]);

  const handleReturnClick = (event) => {
    event.preventDefault();
    navigate("/login");
  };
  async function handleClick() {
    setDisableInput(true);
    setDisableButton(true);
    setLoading("block");
    setDisableLink(true);
    const correctEmail = await forgotPassword(email);

    if (correctEmail.status === 200) {
      navigate("/forgotPasswordTwoFa");
    } else {
      setLoading("none");
      setDisableButton(false);
      setDisableInput(false);
      setOpenSnack(true);
      setDisableLink(false);
    }
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(() => {
    if (validEmail) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [validEmail]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          rowSpacing={5}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>Forgot Password</h2>
            <Typography
              variant="body1"
              style={{ textAlign: "center", padding: "15px" }}
            >
              Please enter your email address and a validation code will be sent
              to you.
            </Typography>
          </Grid>

          <Grid item>
            <label for="example9" class="input-label">
              Email
            </label>
            <div id="email-input" class={emailInputClass}>
              <div class="input-group ">
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => {
                    setEmailInputClass("xrx-input input-focus-first-click");
                  }}
                  onBlur={(e) => {
                    setEmailInputClass("xrx-input");
                  }}
                  aria-label="Email"
                  error={!validEmail}
                  disabled={disableInput}
                  required
                />
              </div>
              <div class="form-text">
                <div class="message message-warning">{emailHelperText}</div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div class="cb">
              <button
                type="button"
                class="xrx-btn btn-primary btn-block btn-login"
                onClick={handleClick}
                disabled={disableButton}
              >
                <span class="btn-label"> Submit</span>
              </button>
            </div>
          </Grid>

          <Grid item>
            <div class="cb">
              <button
                type="button"
                class="xrx-btn btn-default btn-block btn-login"
                onClick={handleReturnClick}
                disabled={disableLink}
              >
                <span class="btn-label"> Return to Login</span>
              </button>
            </div>
          </Grid>
          <Grid
            justify="center"
            item
            sx={{
              display: loading,
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
          <Grid>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              onClose={handleCloseSnack}
              message="Could not sent verification code. Please validate your email and try again."
              autoHideDuration={3000}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;
