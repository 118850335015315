import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GetModel } from "../../../api/documentType";
import MenuItem from "@mui/material/MenuItem";
import { GetAnalyzeDocumentConfiguration } from "../../../api/documentType";
import Button from "@mui/material/Button";
import { SaveTemplate } from "../../../api/documentType";
import { Snackbar } from "@mui/material";
import { GetDocumentTypeTemplateById } from "../../../api/documentType";

function TemplateDetails(data) {
  const [templateDetails, setTemplateDetails] = useState("");
  const [templateTempName, setTemplateTempName] = useState("");
  const [tempConfidence, setTempConfidence] = useState("");

  const [ignoreFieldConfiguration, setIgnoreFieldConfiguration] = useState("");

  const [lLMTemperature, setlLMTemperature] = useState("");
  const [lLMMaxTokenOutput, setlLMMaxTokenOutput] = useState("");
  const [lLMPrompt, setlLMPrompt] = useState("");
  const [lLMBasePrompt, setlLMBasePrompt] = useState("");
  const [analyzeConfigurationArray, setAnalyzeConfigurationArray] = useState(
    []
  );
  const [openSnack, setOpenSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Can't update curent template details, please validate and try again."
  );
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  useEffect(() => {}, [data]);
  //--MODEL
  const [models, setModels] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [configList, setConfigList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTemplateDetailsData();
  }, [data]);

  const fetchTemplateDetailsData = async () => {
    setLoading(true);
    let Tdetails = await GetDocumentTypeTemplateById(data.templateType);
    setTemplateDetails(Tdetails);
    setTemplateTempName(Tdetails.templateName);
    setTempConfidence(Tdetails.confidence);
    setLoading(false);
  };

  const handleChange = (event) => {
    //setChecked(event.target.checked);
  };
  useEffect(() => {
    (async () => {
      const modelList = await GetModel();
      setModels(modelList);
      const anaConfig = await GetAnalyzeDocumentConfiguration();
      setAnalyzeConfigurationArray(anaConfig);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const anaConfigItems = analyzeConfigurationArray.map((item) => (
        <FormGroup>
          <FormControlLabel
            id={item.id}
            control={<Checkbox color="secondary" />}
            onChange={handleChange}
            label={item.configurationName}
          />
        </FormGroup>
      ));
      setConfigList(anaConfigItems);
    })();
  }, [analyzeConfigurationArray]);
  useEffect(() => {
    (async () => {
      const modelItems = models.map((model) => (
        <MenuItem value={model.modelName} data-key={model.id} name={model.id}>
          {model.modelName}
        </MenuItem>
      ));
      setModelList(modelItems);
    })();
  }, [models]);

  const handleSave = async () => {
    const id = data.templateType;

    const templateName = templateTempName;
    const confidence = tempConfidence;

    const llmTemperature = templateDetails["llmTemperature"];
    const llmMaxTokenOutput = templateDetails["llmMaxTokenOutput"];
    const llmBasePrompt = templateDetails["llmBasePrompt"];
    const llmPrompt = templateDetails["llmPrompt"];
    const model = templateDetails["model"];
    const searchCriteria = templateDetails["searchCriteria"];

    const ignoreFieldConfiguration =
      templateDetails["ignoreFieldConfiguration"];
    const templateToBuildJSONString =
      templateDetails["templateToBuildJSONString"];
    const analyzeConfiguration = templateDetails["analyzeConfiguration"];

    const updateCurrentTemplate = await SaveTemplate(
      id,
      templateName,
      llmTemperature,
      llmMaxTokenOutput,
      llmBasePrompt,
      llmPrompt,
      model,
      searchCriteria,
      confidence,
      ignoreFieldConfiguration,
      templateToBuildJSONString,
      analyzeConfiguration
    );
    if (updateCurrentTemplate === 200) {
      fetchTemplateDetailsData();
      setErrorMessage("Template details updated successfully.");
      setOpenSnack(true);
    } else {
      setErrorMessage(
        "Could no update template details, please validate and try again."
      );
      setOpenSnack(true);
    }
  };

  if (
    templateDetails.length < 1 ||
    analyzeConfigurationArray.length < 1 ||
    loading
  ) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <h6>Template Details</h6>
      <Grid container spacing={2}>
        <Grid item md={4} xs={4}>
          <FormControl>
            <TextField
              id="outlined-required"
              label="Template Name"
              value={templateTempName}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setTemplateTempName(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        {/* 
        <Grid item md={4} xs={4}>
         
          <FormControl>
            <InputLabel id="model-simple-select-label" color="info">
              Model
            </InputLabel>
            <Select
              labelId="model-select-label"
              id="model-select"
              label="Model"
              value={modelName}
              onChange={(e) => {
                setModelName(e.target.value);
              }}
              color="info"
              sx={{ width: 400 }}
            >
              {modelList}
            </Select>
          </FormControl> 
        </Grid>*/}
        <Grid item md={4} xs={4}>
          <FormControl>
            <TextField
              type="number"
              id="outlined-required"
              label="Confidence"
              value={tempConfidence}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setTempConfidence(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        {modelName.includes("LLM") ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                // type="number"
                id="outlined-required"
                label="LLM Temperature"
                value={lLMTemperature}
                color="secondary"
                sx={{ width: 400 }}
                onChange={(e) => {
                  setlLMTemperature(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        {modelName.includes("LLM") ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="number"
                id="outlined-required"
                label="LLM Max Token"
                value={lLMMaxTokenOutput}
                onChange={(e) => {
                  setlLMMaxTokenOutput(e.target.value);
                }}
                color="secondary"
                sx={{ width: 400 }}
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}

        {modelName.includes("LLM") ? (
          <Grid item md={8} xs={8}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ignoreFieldConfiguration}
                      color="secondary"
                      onChange={(e) => {
                        setIgnoreFieldConfiguration(e.target.checked);
                      }}
                    />
                  }
                  label="Ignore Field Configuration"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      {modelName.includes("LLM") && !ignoreFieldConfiguration ? (
        <Grid item md={8} xs={8} mt={2}>
          <FormControl>
            <TextField
              multiline
              type="textbox"
              minRows={8}
              id="outlined-required"
              label="LLM Base Prompt"
              value={lLMBasePrompt}
              color="secondary"
              sx={{ width: 800 }}
              onChange={(e) => {
                setlLMBasePrompt(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      ) : (
        ""
      )}
      {modelName.includes("LLM") && ignoreFieldConfiguration ? (
        <Grid item md={8} xs={8} mt={2}>
          <FormControl>
            <TextField
              multiline
              minRows={8}
              type="textbox"
              id="outlined-required"
              label="LLM Prompt"
              value={lLMPrompt}
              color="secondary"
              sx={{ width: 800 }}
              onChange={(e) => {
                setlLMPrompt(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      ) : (
        ""
      )}
      {/* 
      {modelName.includes("AWS") ? (
        <Grid item md={4} xs={4} mt={2}>
          <FormControl>
            <TextField
              type="textbox"
              id="outlined-required"
              label="Search Criteria"
              value={searchCriteria}
              color="secondary"
              sx={{ width: 400 }}
              onChange={(e) => {
                setSearchCriteria(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
      ) : (
        ""
      )}

      {/*modelName.includes("AWS") ? (
        <Grid item md={4} xs={4}>
          <p>AnalyzeConfiguration</p>
          <FormControl>{configList}</FormControl>
        </Grid>
      ) : (
        ""
      )
      {modelName.includes("AWS") ? (
        <Grid item md={6} xs={6} mt={2}>
          <FormControl>
            <TextField
              type="textbox"
              id="outlined-required"
              label="Template To Build JSONString"
              multiline
              maxRows={8}
              sx={{ width: 800 }}
              value={templateToBuildJSONString}
              color="secondary"
            />
          </FormControl>
        </Grid>
      ) : (
        ""
      )} */}
      <Grid item md={12} xs={12} mt={2}>
        <Box sx={{ width: 400 }}>
          {" "}
          <Button
            variant="outlined"
            color="info"
            class="xrx-btn btn-default btn-block"
            component="label"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Grid>
      <Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          onClose={handleCloseSnack}
          message={errorMessage}
          autoHideDuration={3000}
        />
      </Grid>
    </>
  );
}
export default TemplateDetails;
