import React from "react";
import { GetDocuments } from "./api/document";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import "./App.css";
import "./completed.css";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ProtectedRoutes from "./utils/protectedRoutes";
import { useLocation } from "react-router-dom";
function Completed() {
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    document.title = "Completed Documents";
  }, []);
  const [documents, setDocuments] = useState();
  const [nameFilter, setNameFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);
  const [statusFilter, setStatusFilter] = useState(3);
  const [viewingCount, setViewingCount] = useState();

  const [validRoute, setValidRoute] = useState(false);
  useEffect(() => {
    async function fetchValidation() {
      const isValid = await ProtectedRoutes(location.pathname);
      setValidRoute(isValid);
      if (!isValid) {
        navigate("/forbidden");
      }
    }
    fetchValidation();
  }, []);

  useEffect(() => {
    (async () => {
      const getdocuments = await GetDocuments();
      const arrayDocuments = Array.from(getdocuments);
      setDocuments(
        arrayDocuments // sort by value
          .sort(function (a, b) {
            return a.value - b.value;
          })
      );
      setViewingCount(arrayDocuments.length);
    })();
  }, []);

  const handleShowCompleted = async (event) => {
    setShowCompleted(!showCompleted);
    if (!showCompleted) {
      setStatusFilter(2);
    } else {
      setStatusFilter(3);
    }
  };

  if (!documents || !validRoute) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (documents.size < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <h4>No documents.</h4>
      </Grid>
    );
  }

  function btnClick(did) {
    navigate("/document/" + did, { state: { documents } });
  }

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "90vh" }}
    >
      <Grid xs={12} container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 60 }}
          >
            <h4>
              Completed Documents
              <Chip label={documents.length} variant="filled" sx={{ ml: 2 }} />
            </h4>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ ml: 2, height: 60 }}
          >
            Show me
            <Select
              labelId="filetype-simple-select-label"
              id="filetype-simple-select"
              value={typeFilter}
              //label="File Type"
              onChange={(e) => setTypeFilter(e.target.value)}
              color="secondary"
              sx={{ ml: 2, mr: 2 }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Receipt"}>Receipts</MenuItem>
              <MenuItem value={"Invoice"}>Invoices</MenuItem>
              <MenuItem value={"Purchase Order"}>Purchase Orders</MenuItem>
            </Select>
            files, sorted by
            <Select
              labelId="filetype-simple-select-label"
              id="filetype-simple-select"
              value={dateFilter}
              //label="File Type"
              onChange={(e) => setDateFilter(e.target.value)}
              color="secondary"
              sx={{ ml: 2, mr: 2 }}
            >
              <MenuItem value={true}>Newest </MenuItem>
              <MenuItem value={false}>Oldest</MenuItem>
            </Select>
            {showCompleted ? (
              <Tooltip title="Show reviewed" arrow>
                <IconButton
                  aria-label="show reviewed"
                  sx={{ ml: 2 }}
                  onClick={handleShowCompleted}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Hide reviewed" arrow>
                <IconButton
                  aria-label="show reviewed"
                  sx={{ ml: 2 }}
                  onClick={handleShowCompleted}
                >
                  <CheckCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 60 }}
          >
            <FormControl>
              <TextField
                id="outlined-required"
                value={nameFilter}
                type="text"
                color="secondary"
                placeholder="Search"
                onChange={(event) => setNameFilter(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={(event) => setNameFilter("")}
                        edge="end"
                      >
                        {nameFilter.length > 0 ? <ClearIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <List
        style={{ height: "80vh", overflow: "auto" }}
        sx={{
          width: "100%",
          mt: 1,

          // hover states
          "& .MuiListItem-root:hover": {
            bgcolor: "#dbdbe3",
          },
        }}
      >
        {documents
          .filter(
            (f) =>
              (f.fileName.toLowerCase().includes(nameFilter.toLowerCase()) ||
                nameFilter === "") &&
              (f.documentType.includes(typeFilter) || typeFilter === "All") &&
              (f.statusIdApp === 2 || f.statusIdApp === statusFilter)
          )
          .map((obj) => {
            return { ...obj, date: new Date(obj.dateUpdated) };
          })
          .sort((a, b) => (dateFilter ? b.date - a.date : a.date - b.date))

          .map((document, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  id={document.fileName}
                  onClick={() => btnClick(document.id)}
                >
                  <ListItemAvatar>
                    {document.stateId == 4 ? (
                      <p class="xglyph-font">&#57368;</p>
                    ) : (
                      <p class="xglyph-font">&#57816;</p>
                    )}
                  </ListItemAvatar>

                  <ListItemText
                    primary={document.fileName}
                    secondary={document.dateUpdated.substring(0, 10)}
                  />

                  {document.stateId == 4 ? (
                    <Chip
                      label="Error"
                      size="small"
                      variant="outlined"
                      color="error"
                      sx={{ mr: 1 }}
                    />
                  ) : (
                    ""
                  )}
                  <Chip
                    label={document.documentType}
                    size="small"
                    variant="outlined"
                    sx={{ mr: 2 }}
                  />

                  {document.statusIdApp == 2 ? (
                    <Tooltip title="Ready for review" arrow>
                      <RemoveDoneIcon color="info" />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Review completed" arrow>
                      <DoneIcon color="secondary" />
                    </Tooltip>
                  )}
                </ListItem>
                <Divider />
              </>
            );
          })}
      </List>
    </Grid>
  );
}

export default Completed;
