import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { ExportInformation } from "../../../api/document";
export default function ExportBar(fileId) {
  const currentFileName = fileId.fileName;
  const currentFileNameArray = currentFileName.split(".");
  const currentFileNameNoExtension = currentFileNameArray[0];
  const currentFileID = fileId.fileId.id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (event) => {
    const infoIndex = event.currentTarget.id;
    const exportInfo = await ExportInformation(currentFileID, infoIndex);
    const element = document.createElement("a");

    switch (infoIndex) {
      case "1":
        let blob = "";
        if (exportInfo.length === 1) {
          blob = new Blob([JSON.stringify(exportInfo[0])], {
            type: "application/json",
          });
        } else {
          blob = new Blob([JSON.stringify(exportInfo)], {
            type: "application/json",
          });
        }

        const url = URL.createObjectURL(blob);
        const fileName = currentFileNameNoExtension + ".json";
        element.href = url;
        element.setAttribute("download", fileName);
        document.body.appendChild(element);
        element.click();
        break;
      case "2":
        const blob1 = new Blob([exportInfo], { type: "text/xml" });
        const url1 = URL.createObjectURL(blob1);
        const fileName2 = currentFileNameNoExtension + ".xml";
        element.href = url1;
        element.setAttribute("download", fileName2);
        document.body.appendChild(element);
        element.click();
        break;
      case "3":
        element.href = exportInfo.responseURL;
        const fileName3 = currentFileNameNoExtension + ".xlsx";
        element.setAttribute("download", fileName3);
        document.body.appendChild(element);
        element.click();
        break;
      default:
        return;
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="info"
        variant="text"
        sx={{ textTransform: "none" }}
      >
        Export information
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose} id="1" index="1">
          .JSON
        </MenuItem>
        <MenuItem onClick={handleClose} id="2">
          .XML
        </MenuItem>
        <MenuItem onClick={handleClose} id="3">
          .XLSX
        </MenuItem>
      </Menu>
    </div>
  );
}
