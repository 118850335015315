import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function BarCustomChart() {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <BarChart
      xAxis={[
        {
          scaleType: "band",
          data: [
            "Bill of Lading",
            "Domestic Transcript",
            "Identity Documents",
            "Invoice",
            "Legal",
            "Purchase Order",
            "Receipt",
          ],
        },
      ]}
      series={[{ data: [42, 11, 5, 22, 42, 3, 2], color: "#3d58c1" }]}
      width={matches ? 700 : 350}
      height={300}
    />
  );
}
