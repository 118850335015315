import * as React from "react";
import { Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import dayjs from "dayjs";

function DateRangePicker({ onData }) {
  const [value, setValue] = React.useState(null);
  //default startDate will be one month ago
  const [startDate, setStartDate] = React.useState(
    dayjs().subtract(1, "month")
  );
  //default endDate will be today
  const [endDate, setEndDate] = React.useState(dayjs());

  const sendDataToParent = () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    onData(data);
  };
  const handleStartDate = (newValue) => {
    setStartDate(newValue);
    sendDataToParent();
  };
  const handleEndDate = (newValue) => {
    setEndDate(newValue);
    sendDataToParent();
  };
  React.useEffect(() => {
    sendDataToParent();
  }, []);
  return (
    <>
      <Grid container spacing={1} justify="center">
        <Grid item xs={6} align="center">
          <InputLabel>Start Date</InputLabel>
          <DatePicker
            value={startDate}
            onChange={(newValue) => handleStartDate(newValue)}
          />
        </Grid>
        <Grid item xs={6} align="center">
          <InputLabel>End Date</InputLabel>
          <DatePicker
            value={endDate}
            onChange={(newValue) => handleEndDate(newValue)}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default DateRangePicker;
