import DateRangePicker from "../../components/dateRangePicker";
import BarCustomChart from "../charts/BarCustomChart";
import PieCustomChart from "../charts/PieCustomChart";
import LineCustomChart from "../charts/LineCustomChart";
function GraphCard(props) {
  const handleData = (childData) => {
    console.log(childData);
  };
  return (
    <div class="dashboard-card">
      <div class="xrx-card ">
        <div class="card-1line-title">
          <h6> {props.tittle}</h6>
          <div class="right-object">
            <DateRangePicker onData={handleData} />
          </div>
        </div>
        <div class="card-body pt-0" className="dashboard-graph-card">
          {props.type.toString().includes("bar") ? <BarCustomChart /> : ""}
          {props.type.toString().includes("pie") ? <PieCustomChart /> : ""}
          {props.type.toString().includes("line") ? <LineCustomChart /> : ""}
        </div>
      </div>
    </div>
  );
}
export default GraphCard;
