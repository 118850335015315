import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import CreateUser from "./createUser";
import { getUsers } from "../../api/adminUsers";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { getUserRoles } from "../../api/adminUsers";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { deleteUser } from "../../api/adminUsers";
import { updateUserRole } from "../../api/adminUsers";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
function UserAdmin(props) {
  const [getUsersList, setGetUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleList, setRoleList] = useState("");
  const [rowTotal, setRowTotal] = useState([]);
  const [generalFilter, setGeneralFilter] = useState("");
  const [userDataTable, setUserDataTable] = useState([]);
  const [data, setData] = useState(props.data);
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const [errorMessage, setErrorMessage] = useState(
    "Could not create user. Try again."
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [delayedSearchTerm, setDelayedSearchTerm] = React.useState("");

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      setDelayedSearchTerm(searchTerm);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Users",
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "role",
      numeric: true,
      disablePadding: false,
      label: "Role",
    },
    {
      id: "active",
      numeric: true,
      disablePadding: false,
      label: "Active",
    },
  ];

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="info"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const handleDelete = async (event) => {
      event.preventDefault();
      props.itemSelected.forEach(async (element) => {
        const removeUser = await deleteUser(element);
        if (removeUser.status === 200) {
          await fetchUserData();
          setErrorMessage("User(s) deleted successfully.");
          setOpenSnack(true);
          props.itemSelected.shift();
        } else {
          setErrorMessage("User could not be deleted.");
          setOpenSnack(true);
        }
      });
    };
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users
          </Typography>
        )}

        <TextField
          id="outlined-search"
          label="Search field"
          type="search"
          color="secondary"
          size="small"
          style={{ width: 400 }}
          onBlur={(e) => setGeneralFilter(e.target.value)}
          onKeyDown={(e) => {
            e.key === "Enter" ? setGeneralFilter(e.target.value) : null;
          }}
          onChange={(e) => {
            e.target.value < 1 ? setGeneralFilter("") : null;
          }}
          defaultValue={generalFilter}
        />

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    itemSelected: PropTypes.array.isRequired,
  };

  const EnhancedTable = () => {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("Name");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = useState([]);

    const fetchUserData = async () => {
      setLoading(true);
      const users = await getUsers(
        page + 1,
        rowsPerPage,
        orderBy.charAt(0).toUpperCase() +
          orderBy.slice(1) +
          " " +
          (order === "asc" ? "ascending" : "descending"),
        generalFilter
      );
      setGetUsersList(users.results);
      setRows(users.results);
      setRowTotal(users.qty);
      setLoading(false);
      setSelected([]);
    };
    useEffect(() => {
      fetchUserData();
    }, [props, page, order, generalFilter, rowsPerPage]);

    useEffect(() => {
      setSelected([]);
    }, []);
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
    const handleRole = async (event, id) => {
      const newRoleId = returnIndex(event.target.value);
      //const newEmail = returnEmail(id);
      const newName = returnName(id);
      const newStatus = returnStatus(id);

      const updateCurrentUserRole = await updateUserRole(
        id,
        newName,
        newStatus,
        newRoleId
      );
      if (updateCurrentUserRole === 200) {
        fetchUserData();
        setErrorMessage("User role updated successfully.");
        setOpenSnack(true);
      } else {
        setErrorMessage("Could no update user role.");
        setOpenSnack(true);
      }
    };
    const handleStatus = async (event, id) => {
      const newRoleId = returnRole(id);
      //const newEmail = returnEmail(id);
      const newName = returnName(id);
      let newStatus = event.target.checked;

      const updateCurrentUserStatus = await updateUserRole(
        id,
        newName,
        newStatus,
        newRoleId
      );
      if (updateCurrentUserStatus === 200) {
        fetchUserData();
        setErrorMessage("User status updated successfully.");
        setOpenSnack(true);
      } else {
        setErrorMessage("Could not update user status.");
        setOpenSnack(true);
      }
    };

    const handleChangePage = async (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowTotal) : 0;

    const visibleRows = React.useMemo(
      () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        ),
      [order, orderBy, page, rowsPerPage, rows]
    );
    useEffect(() => {
      const fetchData = async () => {
        const getRoles = await getUserRoles();
        setUserRoles(getRoles);
        setRole(getRoles[0].id);
        setRoleName(getRoles[0].roleName);
        const listItems = getRoles.map((role) => (
          <MenuItem value={role.roleName} data-key={role.id} name={role.id}>
            {role.roleName}
          </MenuItem>
        ));
        setRoleList(listItems);
      };

      fetchData();
    }, []);

    function returnIndex(roleName) {
      const index = userRoles.find((o) => o.roleName === roleName);
      return index.id;
    }
    function returnName(id) {
      const index = rows.find((o) => o.id === id);
      return index.name;
    }
    function returnStatus(id) {
      const index = rows.find((o) => o.id === id);
      return index.isActive;
    }
    function returnRole(id) {
      const index = rows.find((o) => o.id === id);
      return index.roleID;
    }
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              itemSelected={selected}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        //onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        color="info"
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="info"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">
                          <Select
                            labelId="filetype-simple-select-label"
                            id="filetype-simple-select"
                            value={row.roleName}
                            //disabled={disableInput}
                            onChange={(event) => handleRole(event, row.id)}
                            color="info"
                            sx={{ width: 350 }}
                            size="small"
                          >
                            {roleList}
                          </Select>
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="info"
                            defaultChecked={row.isActive}
                            // value={row.isActive}
                            onClick={(event) => handleStatus(event, row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  };
  const customJSX = EnhancedTable();

  if (loading || roleList.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      {customJSX}
      <Grid container>
        <Grid item>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnack}
            onClose={handleCloseSnack}
            message={errorMessage}
            autoHideDuration={3000}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UserAdmin;
