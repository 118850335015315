import axiosApiInstance from "./axiosApiInstance";
const apiURL = process.env.REACT_APP_AUTH_API_URL;

export async function createExternalUser(
  name,
  email,
  roleID,
  isActive,
  LDAPUser
) {
  const url = apiURL + "/api/AdminUsers/CreateUser";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Name: name,
    Email: email,
    RoleID: roleID,
    IsActive: isActive,
    IsExternalUser: LDAPUser,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getUserRoles() {
  const url = apiURL + "/api/AdminUsers/GetRoles";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getUserById(id) {
  const url = apiURL + "/api/AdminUsers/GetUserById/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getUsers(pageNo, pageSize, columnOrder, filter) {
  const url = apiURL + "/api/AdminUsers/GetUsers";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    PageNo: pageNo,
    PageSize: pageSize,
    ColumnOrder: columnOrder,
    Filters: { GeneralFilter: filter },
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteUser(id) {
  const url = apiURL + "/api/AdminUsers/DeleteUser/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .delete(url, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function updateUserRole(id, name, status, role) {
  const url = apiURL + "/api/AdminUsers/UpdateUser";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Id: id,
    Name: name,
    isActive: status,
    RoleID: role,
  };
  return await axiosApiInstance
    .put(url, body, { headers })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}

export async function bulkUploadUser(fileToUpload) {
  console.log(fileToUpload);
  const url = apiURL + "/api/AdminUsers/BulkUploadUser";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
    "Content-Type": "multipart/form-data",
  };

  return await axiosApiInstance
    .post(url, fileToUpload, { headers })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
