//Receives URL returns QR code on UI
import QRCode from "qrcode";

function GenerateQR(url) {
  let resulted;
  let anyVar = QRCode.toDataURL(
    url,
    {
      errorCorrectionLevel: "H",
      type: "svg",
    },
    function (err, data) {
      if (err) throw err;
      resulted = data;
    }
  );
  return resulted;
}

export default GenerateQR;
