import "./forbidden.css";
function Forbidden() {
  return (
    <>
      <div class="flex-container">
        <h1>Error 403</h1>

        <h2>Forbidden</h2>

        <p>You don't have permission to access this resource.</p>
        <p>
          {" "}
          Go <a href="/">back.</a>
        </p>
      </div>
    </>
  );
}
export default Forbidden;
