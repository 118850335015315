import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { FileUpload } from "./api/fileUpload";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ErrorIcon from "@mui/icons-material/Error";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import { AllowedUploadExtensionFile } from "./api/general";
import { getDocumentTypes } from "./api/general";
import ProtectedRoutes from "./utils/protectedRoutes";
import { useLocation, useNavigate } from "react-router-dom";

function UploadFiles() {
  let navigate = useNavigate();
  let location = useLocation();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileType, setFileType] = useState();
  const [fileTypeName, setFileTypeName] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState("none");
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypesList, setDocumentTypesList] = useState("");
  const [allowedFiles, setAllowedFiles] = useState([]);

  //Valid Routing
  const [validRoute, setValidRoute] = useState(false);
  useEffect(() => {
    async function fetchValidation() {
      const isValid = await ProtectedRoutes(location.pathname);
      setValidRoute(isValid);
      if (!isValid) {
        navigate("/forbidden");
      }
    }
    fetchValidation();
  }, []);

  useEffect(() => {
    (async () => {
      const documentType = await getDocumentTypes();
      setDocumentTypes(documentType);
      setFileTypeName(documentType[0].templateName);
      setFileType(documentType[0].id);
      const allowedExtensionFiles = await AllowedUploadExtensionFile();
      let newExtensionList = allowedExtensionFiles.map((extension) => ({
        //id: extension.id,
        value: extension.extensionName,
      }));
      const finalArray = newExtensionList.map(function (obj) {
        return obj.value;
      });
      setAllowedFiles(finalArray);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const listItems = documentTypes.map((documentType) => (
        <MenuItem
          value={documentType.templateName}
          data-key={documentType.id}
          name={documentType.id}
        >
          {documentType.templateName}
        </MenuItem>
      ));
      setDocumentTypesList(listItems);
    })();
  }, [documentTypes]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async function handleClick() {
    setDisableButton(true);
    setDisableUploadButton(true);
    setLoading("block");
    const fileList = Array.from(selectedFiles[0]);
    const filterFileList = [];
    filterFileList[0] = fileList;
    // setSelectedFiles(filterFileList);
    for (const file of fileList) {
      const fu = await FileUpload(file, fileType, fileTypeName);
      //setFileUpload(fu);

      if (fu) {
        file.status = <CheckBoxIcon color="success" />;
      } else {
        file.status = <ErrorIcon color="error" />;
      }
    }

    setLoading("none");
    setSelectedFiles(filterFileList);
    // setDisableUploadButton(false);
    // setDisableButton(false);
  }
  function returnIndex(fileTypeName) {
    const index = documentTypes.find((o) => o.templateName === fileTypeName);
    setFileType(index.id);
  }
  function handleRemoveItem(name) {
    const fileList = Array.from(selectedFiles[0]);
    const filterFileList = [];
    filterFileList[0] = fileList.filter((item) => item.name !== name);
    setSelectedFiles(filterFileList);
  }
  useEffect(() => {}, [fileTypeName]);
  useEffect(() => {}, [fileType]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setDisableUploadButton(false);

      const fileList = Array.from(selectedFiles[0]);
      //ar = ar.filter((item) => !(item > 3));
      const validatedFileList = fileList.filter(validExtension);

      function validExtension(item) {
        function checkExtension(v) {
          return item.name.toLowerCase().includes(v);
        }
        const found = allowedFiles.find(checkExtension);

        return found;
      }

      // console.log(validatedFileList);
      setFilesToUpload(
        validatedFileList.map((file, index) => (
          <>
            <ListItem key={index}>
              <ListItemAvatar>
                <i class="xgl-file xglsize-24"></i>
              </ListItemAvatar>
              <ListItemText primary={file.name} secondary={file.type} />
              <Chip
                label={formatBytes(file.size)}
                variant="outlined"
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              />
              <Chip
                sx={{ ml: 2, mr: 2 }}
                label={fileTypeName}
                variant="outlined"
              />

              <IconButton
                name={index}
                color="secondary"
                aria-label="remove item"
                component="span"
                data-remove={index}
                disabled={disableButton}
                onClick={() => handleRemoveItem(file.name)}
              >
                <i class="xgl-trash xglsize-24"></i>
              </IconButton>
              <Box
                sx={{
                  display: loading,
                }}
              >
                <CircularProgress color="secondary"></CircularProgress>
              </Box>

              {file.status}
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))
      );
    } else {
      setFilesToUpload("");
      setDisableUploadButton(true);
    }
  }, [selectedFiles, loading]);

  if (documentTypes.length < 1 || allowedFiles.length < 1 || !validRoute) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "90vh" }}
    >
      <Grid xs={12} container direction="row" spacing={2}>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 75 }}
          >
            <FormControl sx={{ m: 1, minWidth: 160, height: 60 }} color="info">
              <InputLabel id="filetype-simple-select-label" color="info">
                File Type
              </InputLabel>

              <Select
                labelId="filetype-simple-select-label"
                id="filetype-simple-select"
                label="File Type"
                value={fileTypeName}
                onChange={(e) => {
                  setSelectedFiles([]);
                  setFileTypeName(e.target.value);
                  returnIndex(e.target.value);
                  //setFileType(e.target.hasOwnProperty("data-key"));
                }}
                color="info"
                sx={{ width: 200 }}
              >
                {documentTypesList}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 75 }}
          >
            <FormControl sx={{ m: 1, minWidth: 160, height: 60 }}>
              <Button
                variant="outlined"
                color="secondary"
                class="xrx-btn btn-default btn-block"
                component="label"
                sx={{ ml: 2, mr: 2, width: 200 }}
              >
                Select file(s)
                <input
                  type="file"
                  accept={allowedFiles}
                  onChange={(e) => setSelectedFiles([e.target.files])}
                  multiple
                  hidden
                  color="secondary"
                  excludeAcceptAllOption="true"
                />
              </Button>
              <FormHelperText>
                Allowed extensions:{" "}
                {allowedFiles.map((txt) => (
                  <span>{txt} </span>
                ))}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 75 }}
          >
            <Grid item>
              <div class="cb">
                <button
                  type="button"
                  class="xrx-btn btn-primary btn-block"
                  disabled={disableUploadButton}
                  onClick={handleClick}
                >
                  <span class="btn-label">Upload</span>
                </button>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <List
        style={{ height: "80vh", overflow: "auto" }}
        sx={{
          width: "100%",
        }}
      >
        {filesToUpload}
      </List>
    </Grid>
  );
}

export default UploadFiles;
