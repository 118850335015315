import React, { useState, useEffect } from "react";
//import { FieldsByTemplate } from "./api/documentType";
import { GetDocumentTypeTemplateByCompany } from "./api/documentType";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetDocumentTypeTemplateById } from "./api/documentType";
import TemplateDetails from "./screens/document/views/templateDetails";
import TemplateFields from "./screens/document/views/templateFields";
//import NewTemplateForm from "./screens/forms/newTemplateForm";
function Setup() {
  const [templates, setTemplates] = useState([]);
  //const [summaryFieldDocuments, setSummaryFieldDocuments] = useState([]);
  // const [lineItemsdDocuments, setLineItemsdDocuments] = useState([]);
  const [templateType, setTemplateType] = useState(1);
  const [templateTypeName, setTemplateTypeName] = useState("");
  const [templateTypesList, setTemplateTypesList] = useState("");
  const [templateDetails, setTemplateDetails] = useState([]);
  // const [displayNewTemplateForm, setDisplayNewTemplateForm] = useState("none");

  function returnIndex(templateTypeName) {
    const index = templates.find((o) => o.templateName === templateTypeName);
    setTemplateType(index.id);
  }

  useEffect(() => {
    document.title = "Setup Documents";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let Tdetails = await GetDocumentTypeTemplateById(templateType);
      setTemplateDetails(Tdetails);
    };
    fetchData();
  }, [templateType]);

  useEffect(() => {
    const listItems = templates.map((template) => (
      <MenuItem
        value={template.templateName}
        data-key={template.id}
        name={template.id}
      >
        {template.templateName}
      </MenuItem>
    ));
    setTemplateTypesList(listItems);
  }, [templates]);

  {
    /* 
  useEffect(() => {
    const fetchData = async () => {
      const URLData = await FieldsByTemplate(templateType);

      let SummaryDocuments = filterByParentName(URLData, "SummaryFields");
      setSummaryFieldDocuments(SummaryDocuments);
      let LineDocuments = filterByParentName(URLData, "LineItems");
      setLineItemsdDocuments(LineDocuments);
    };

    fetchData();
  }, [templateType]);
*/
  }
  useEffect(() => {
    const fetchData = async () => {
      const availableTemplates = await GetDocumentTypeTemplateByCompany();
      setTemplates(availableTemplates);
      setTemplateTypeName(availableTemplates[0].templateName);
      setTemplateType(availableTemplates[0].id);
    };
    fetchData();
  }, []);

  if (templates.length < 1 || templateDetails.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <>
      {" "}
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ minHeight: "90vh", padding: "5vh", paddingTop: "1vh" }}
      >
        <h4> Setup Documents</h4>
        <Grid container>
          <Grid item xs="12">
            <h6>Available Templates</h6>
          </Grid>
          <Grid item xs="4">
            {" "}
            <FormControl sx={{ m: 1, minWidth: 160, height: 60 }} color="info">
              <Select
                labelId="filetype-simple-select-label"
                id="filetype-simple-select"
                value={templateTypeName}
                onChange={(e) => {
                  setTemplateTypeName(e.target.value);
                  returnIndex(e.target.value);
                  //setFileType(e.target.hasOwnProperty("data-key"));
                }}
                color="info"
                sx={{ width: 200 }}
              >
                {templateTypesList}
              </Select>
            </FormControl>
          </Grid>
          {/* 
          <Grid item xs="2">
            <Button
              variant="outlined"
              color="info"
              class="xrx-btn btn-default btn-block"
              component="label"
              sx={{ ml: 2, mr: 2, maxWidth: 200 }}
              onClick={handleAddNew}
            >
              + new template
            </Button>
          </Grid>*/}
          {/* 
          <Grid item xs="12" display={displayNewTemplateForm}>
            <NewTemplateForm data={true} />
          </Grid> */}

          <Grid item xs="12">
            <TemplateDetails
              data={templateDetails}
              templateType={templateType}
            />
          </Grid>

          <Grid item xs="12">
            <br></br>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md="12">
            <Box>
              <TemplateFields
                parentName={"SummaryFields"}
                templateType={templateType}
                templateModel={templateDetails.model}
              />
            </Box>
          </Grid>
          <Grid item md="12">
            <Box>
              <TemplateFields
                parentName={"LineItems"}
                templateType={templateType}
                templateModel={templateDetails.model}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Setup;
