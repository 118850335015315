import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  PieSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Palette } from "@devexpress/dx-react-chart";
import { Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { userInformation } from "./api/general";
import { AvailableCredit } from "./api/dashboard";
import { QtyProcessedDocuments } from "./api/dashboard";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { GetDocuments } from "./api/document";
import { GetDocumentsPending } from "./api/document";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import "./dashboard.css";
import GraphCard from "./screens/cards/GraphCard";
import ProtectedRoutes from "./utils/protectedRoutes";

function Dashboard() {
  const [user, setUser] = useState([]);
  const [credit, setCredit] = useState([]);
  const [qtyProcessedDocs, setQtyProcessedDocs] = useState();

  useEffect(() => {
    (async () => {
      const currentUser = await userInformation();
      setUser(currentUser);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const availableCredit = await AvailableCredit();
      setCredit(availableCredit[0]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const qtyDocs = await QtyProcessedDocuments();

      setQtyProcessedDocs(qtyDocs);
    })();
  }, []);

  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    (async () => {
      const getdocuments = await GetDocuments();
      const arrayDocuments = Array.from(getdocuments);
      setDocuments(arrayDocuments.length);
      //console.log(arrayDocuments);
      var filterDate = new Date();

      filterDate.setMonth(filterDate.getMonth() - 10);
      const filteredArray = arrayDocuments.filter((document) => {
        let date = new Date(document.dateCreated).getTime();

        return date >= filterDate;
      });
      /* const modifiedArray = _.groupBy(filteredArray, ({ dateCreated }) =>
        new Date(dateCreated).getMonth()
      );
      console.log(modifiedArray);
      */
    })();
  }, []);

  const [pending, setPending] = useState("0");

  useEffect(() => {
    (async () => {
      const getpending = await GetDocumentsPending();
      const arrayPending = Array.from(getpending);
      const date = new Date();

      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const arrayPendingFiltered = arrayPending.filter(
        (f) => f.dateUpdated.includes(month) && f.dateUpdated.includes(year)
      );
      setPending(arrayPendingFiltered.length);
    })();
  }, []);

  if (!qtyProcessedDocs) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <h2>Welcome, {user.names}</h2>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ padding: "1vh", paddingTop: "1vh" }}
      >
        <h5>Welcome, {user.names}</h5>

        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <div class="dashboard-card">
              <Link to="/completed" style={{ textDecoration: "none" }}>
                <div class="xrx-card ">
                  <div class="card-1line-title">
                    <h6> Completed Documents</h6>
                    <div class="right-object">
                      <i class="xgl-file xglsize-32"></i>
                    </div>
                  </div>
                  <div class="card-body pt-0">{documents} </div>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div class="dashboard-card">
              <Link to="/pending" style={{ textDecoration: "none" }}>
                <div class="xrx-card dashboard-card">
                  <div class="card-1line-title">
                    <h6>Pending Documents</h6>
                    <div class="right-object">
                      <i class="xgl-hourglass xglsize-32"></i>
                    </div>
                  </div>
                  <div class="card-body pt-0">{pending} </div>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item xl={4} lg={6} s={12} md={6}>
            <GraphCard tittle="Credits by Category" type="bar" />
          </Grid>
          <Grid item xl={4} lg={6} s={12} md={6}>
            <GraphCard tittle="Documents Processed" type="pie" />
          </Grid>
          <Grid item xl={4} lg={6} s={12} md={6}>
            <GraphCard tittle="Failed Documents" type="line" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;

/*
   <Grid item md={4} xs={6}>
            <div class="xrx-card">
              <div class="card-1line-title">
                <h5> {credit.totalCredits - credit.totalCreditUserDocument}</h5>
                <div class="right-object">
                  <i class="xgl-cost xglsize-32"></i>
                </div>
              </div>
              <div class="card-body pt-0">Credits Available</div>
            </div>
          </Grid>

      */
